import { render, staticRenderFns } from "./VisitationLog.vue?vue&type=template&id=5d884a05&scoped=true&"
import script from "./VisitationLog.vue?vue&type=script&lang=js&"
export * from "./VisitationLog.vue?vue&type=script&lang=js&"
import style0 from "./VisitationLog.vue?vue&type=style&index=0&id=5d884a05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d884a05",
  null
  
)

export default component.exports
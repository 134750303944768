<template>
    <div>
        <div id="fake-container">
            <img id="visitationlog-banner" src="../assets/Home_Banner.png" />
        </div>

        <!-- VISITATION LOG HEADER -->
        <div id="visitationlog-box" v-if="user != ''">
            <b-row align-v="center" align-h="center" class="max-100">
                <div class="visitationlog-secondary">
                    VISITATION LOG
                </div>
            </b-row>
        </div>

        <!-- FILTER  -->
        <template>
            <div id="loghistory-selector" v-if="(user != '') && (error === '')">
                <span>Filter: </span>
                <span id="selector">
                    <select v-on:change="refreshLog">
                        <option v-for="(option, idx) in this.filteroptions" :key="idx" v-bind:value="option">
                            {{ option }}
                        </option>
                    </select>
                </span>
            </div>
        </template>

        <div class="content" v-if="user != ''">

            <!-- ERROR MESSAGE -->
            <b-row id="errormsg" v-if="error" align-h="center" class="max-100"><b>{{ error }}</b></b-row>

            <!-- NOTES -->
            <template>
                <div>
                    <div id="visitationlog-container">
                        <div id="visitationlog-scroll" class="visitationlog-section">
                            <div class="visitationlog-item" v-for="(lognote, obj) in visitationlog" v-bind:key="obj">
                                <div class="lognote-shop-row">
                                    <a href="#" @click="editLogNote"
                                        :record_no="lognote.record_no"
                                        :record_lno="lognote.record_lno"
                                        :employer_name="lognote.employer_name"
                                        :employer_no="lognote.employer_no"
                                        :date="lognote.date"
                                        :time_from="lognote.time_from"
                                        :time_to="lognote.time_to"
                                        :ntext="lognote.note">
                                        {{ lognote.employer_name}}
                                    </a>
                                </div>
                                <div class="lognote-row">
                                    <span>{{ lognote.date }}</span>
                                </div>
                                <div class="lognote-row">
                                    <span>{{ lognote.time_from }}</span> to
                                    <span>{{ lognote.time_to }}</span>
                                </div>
                                <div id="note-container" class="lognote-row">
                                    <div class="lognote-note">{{ lognote.note }}</div>
                                </div>

                            </div>
                            <div id="visitationlog-hidden-div"></div>
                        </div>
                    </div>
                    <div id="visitationlog-list-arrow-more">
                        <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                    </div>

                    <b-row class="visitationlog-header">
                        <b-link id="add-entry" @click="showModal">
                                ADD ENTRY
                        </b-link>
                    </b-row>


                    <!-- ENTRY MODAL -->
                    <b-modal id="visitationlog-modal" no-fade hide-header-close no-close-on-backdrop>
                        <template v-slot:modal-header="">
                            <div>
                                <div id="button-container-top">
                                    <a class="cancel-modal-link" href="#" @click.prevent="cancelModal">
                                        <div class="cancel-modal-button">Cancel</div>
                                    </a>
                                    <a v-if="modaltype === 'edit' && deleteshow === 'no'"
                                        class="delete-modal-link" href="#" @click.prevent="deleteShowModal">
                                        <div class="delete-modal-button">Delete</div>
                                    </a>
                                </div>
                                <div id="error-modal" v-if="errormodal">{{ errormodal }}</div>
                            </div>
                        </template>

                        <template v-slot:default="">
                            <div>
                                <div id="visitationlog-modal-middle" v-if="deleteshow === 'no'">
                                    <b-form-input id="companyname-search" placeholder="Search shop name"
                                      v-model="shopfrag"
                                    ></b-form-input>
                                    <div id="working-message">working...</div>
                                    <div id="search-results">
                                        <div v-for="(shopname, obj) in resultsshopnames" v-bind:key="obj">
                                            <a class="shop-name" href="#" @click.prevent="fillName" :valueno="shopname.employer_no"
                                                :valuename="shopname.employer_name">{{ shopname.employer_name }}</a>
                                        </div>
                                    </div>

                                    <!-- Pick a date -->
                                    <div v-if="modaltype === 'edit'">Date of Visit: {{ selecteddate }}</div>
                                    <div v-if="modaltype === 'new'" id="selected-date">Date of Visit:
                                        <a href="#" @click.prevent="pickDate">{{ selecteddate }}</a>
                                    </div>
                                    <div id="calendar">
                                        <datepicker :inline="true" :value="selecteddate" @selected="datePicked"></datepicker>
                                        Today is: {{ todayis }}
                                    </div>

                                    <!-- Pick Times  -->
                                    <table id="visit-times">
                                        <tr class="time-from">
                                            <td>
                                                From:
                                            </td>
                                            <td>
                                                <span class="time-box">
                                                    <select @change="setHourFrom($event.target.value)">
                                                        <option selected :value="hourfrom">{{ hourfrom }}</option>
                                                        <option disabled>--</option>
                                                        <option value="01">01</option>
                                                        <option value="02">02</option>
                                                        <option value="03">03</option>
                                                        <option value="04">04</option>
                                                        <option value="05">05</option>
                                                        <option value="06">06</option>
                                                        <option value="07">07</option>
                                                        <option value="08">08</option>
                                                        <option value="09">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </span>:
                                                <span class="time-box">
                                                    <select @change="setMinuteFrom($event.target.value)">
                                                        <option selected :value="minutefrom">{{ minutefrom }}</option>
                                                        <option disabled>--</option>
                                                        <option value="00">00</option>
                                                        <option value="30">30</option>
                                                    </select>
                                                </span>
                                                <span class="time-box">
                                                    <select @change="setAmPmFrom($event.target.value)">
                                                        <option selected :value="ampmfrom">{{ ampmfrom }}</option>
                                                        <option disabled>--</option>
                                                        <option value="AM">AM</option>
                                                        <option value="PM">PM</option>
                                                    </select>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                To:
                                            </td>
                                            <td>
                                                <span class="time-to">
                                                    <span class="time-box">
                                                        <select @change="setHourTo($event.target.value)">
                                                            <option selected :value="hourto">{{ hourto }}</option>
                                                            <option disabled>--</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                        </select>
                                                    </span>:
                                                    <span class="time-box">
                                                        <select @change="setMinuteTo($event.target.value)">
                                                            <option selected :value="minuteto">{{ minuteto }}</option>
                                                            <option disabled>--</option>
                                                            <option value="00">00</option>
                                                            <option value="30">30</option>
                                                        </select>
                                                    </span>
                                                    <span class="time-box">
                                                        <select @change="setAmPmTo($event.target.value)">
                                                            <option selected :value="ampmto">{{ ampmto }}</option>
                                                            <option disabled>--</option>
                                                            <option value="AM">AM</option>
                                                            <option value="PM">PM</option>
                                                        </select>
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                    </table>

                                    <!-- Note  -->
                                    <b-form-textarea id="visitationlog-note" rows="3" :value="notetext"
                                        placeholder="Enter note here..." @input="captureNoteText"></b-form-textarea>

                                    <div>Characters: <span id="notecount">0</span>/2000</div>

                                 </div>
                                 <div v-if="deleteshow === 'yes'">Are you sure you want to delete this entry?</div>
                             </div>
                         </template>


                        <template v-slot:modal-footer="">
                            <div id="button-container-bottom">
                                <a v-if="modaltype === 'new'" class="submit-entry-link" href="#" @click.prevent="submitEntry">
                                    <div class="submit-entry-button">Submit Entry</div>
                                </a>
                                <a v-if="modaltype === 'edit' && deleteshow === 'no'" class="submit-entry-link" href="#" @click.prevent="submitEdit">
                                    <div class="submit-entry-button">Submit Edit</div>
                                </a>
                                <a v-if="deleteshow === 'yes'" class="delete-entry-link" href="#" @click.prevent="submitDelete">
                                    <div class="delete-entry-button">Delete Entry</div>
                                </a>
                            </div>

                        </template>

                    </b-modal>
                </div>
            </template>



        </div>
        <div class="footer">
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import Datepicker from 'vuejs-datepicker'
import { mapActions, mapGetters } from 'vuex'

export default {

    name: 'visitationlog',

    components: {
            Datepicker
    },

    created: function() {
        this.debouncedShopName = _.debounce(this.filterBy, 500);
        this.setErrorModal('');
        this.getDateToday();

    },

    mounted: function() {
        if(this.visitationlog != '') {
            this.checkListLength();
        }

    },

    data () {
      return {
          shopfrag: '',
          todayis: ''
      }

    },

    watch: {
        shopfrag: function() {
            if((this.shopfrag.length > 0) && ((this.modaltype === 'new'))
                || ((this.shopfrag.length > 0) && (this.modaltypeoverride === 'yes'))) {
                this.debouncedShopName();
                // console.log('this.modaltype: '+this.modaltype+' this.modaltypeoverride: '+this.modaltypeoverride)
            } else if ((this.modaltype === 'edit') && (this.modaltypeoverride === 'no')) {
                this.setModalTypeOverride('yes');
                // console.log('this.modaltype: '+this.modaltype+' this.modaltypeoverride: '+this.modaltypeoverride)
                // this.debouncedShopName();
            } else {
                this.clearResultsShopNames();
            }
        },

    },

    computed: {
      ...mapGetters({
          user: 'auth/user',
          error: 'visitationlog/error',
          errormodal: 'visitationlog/errormodal',
          modaltype: 'visitationlog/modaltype',
          modaltypeoverride: 'visitationlog/modaltypeoverride',
          visitationlog: 'visitationlog/visitationlog',
          resultsshopnames: 'visitationlog/resultsshopnames',
          selectedshopnumber: 'visitationlog/selectedshopnumber',
          selecteddate: 'visitationlog/selecteddate',
          hourfrom: 'visitationlog/hourfrom',
          minutefrom: 'visitationlog/minutefrom',
          hourto: 'visitationlog/hourto',
          minuteto: 'visitationlog/minuteto',
          ampmfrom: 'visitationlog/ampmfrom',
          ampmto: 'visitationlog/ampmto',
          notetext: 'visitationlog/notetext',
          notelength: 'visitationlog/notelength',
          deleteshow: 'visitationlog/deleteshow',
          recordno: 'visitationlog/recordno',
          recordlno: 'visitationlog/recordlno',
          filteroptions: 'visitationlog/filteroptions',
          datefilter: 'visitationlog/datefilter'
      }),

    },

    methods: {
        ...mapActions({
            getVisitationLog: 'visitationlog/getVisitationLog',
            getFilterOptions: 'visitationlog/getFilterOptions',
            setDateFilter: 'visitationlog/setDateFilter',
            getFilteredLog: 'visitationlog/getFilteredLog',
            searchShopNames: 'visitationlog/searchShopNames',
            clearResultsShopNames: 'visitationlog/clearResultsShopNames',
            setError: 'visitationlog/setError',
            setErrorModal: 'visitationlog/setErrorModal',
            setModalType: 'visitationlog/setModalType',
            setModalTypeOverride: 'visitationlog/setModalTypeOverride',
            setResultsShopNames: 'visitationlog/setResultsShopNames',
            setSelectedShopNumber: 'visitationlog/setSelectedShopNumber',
            setDateSelected: 'visitationlog/setDateSelected',
            setDateToday: 'visitationlog/setDateToday',
            recordVisitationLogNote: 'visitationlog/recordVisitationLogNote',
            setLogtoRecord: 'visitationlog/setLogtoRecord',
            setHourFrom: 'visitationlog/setHourFrom',
            setMinuteFrom: 'visitationlog/setMinuteFrom',
            setHourTo: 'visitationlog/setHourTo',
            setMinuteTo: 'visitationlog/setMinuteTo',
            setAmPmFrom: 'visitationlog/setAmPmFrom',
            setAmPmTo: 'visitationlog/setAmPmTo',
            setNoteText: 'visitationlog/setNoteText',
            setNoteLength: 'visitationlog/setNoteLength',
            setDeleteShow: 'visitationlog/setDeleteShow',
            setRecordNo: 'visitationlog/setRecordNo',
            setRecordLNo: 'visitationlog/setRecordLNo',
            deleteRecord: 'visitationlog/deleteRecord',
            editRecord: 'visitationlog/editRecord',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg'
        }),

        checkListLength() {
            var element_array = {
                containerName: 'visitationlog-container',
                listArrowName: 'visitationlog-list-arrow-more',
                hiddenDivName: 'visitationlog-hidden-div'
            }
            this.flashList(element_array);
            this.onScroll(element_array);

        },

        flashList (element_array) {
            if(this.endOfListVisible(element_array)) {
                setTimeout(function() {
                    document.getElementById(element_array.containerName).classList.add('flash');
                    document.getElementById(element_array.listArrowName).classList.add('flash');
                    setTimeout(function() {
                        document.getElementById(element_array.containerName).classList.remove('flash');
                        document.getElementById(element_array.listArrowName).classList.remove('flash');
                        setTimeout(function() {
                            document.getElementById(element_array.containerName).classList.add('flash');
                            document.getElementById(element_array.listArrowName).classList.add('flash');
                        },100)
                    },250)
                },400)
            }
        },

        onScroll(element_array) {
            window.document.getElementById(element_array.containerName)
                .addEventListener("scroll", () => this.endOfListVisible(element_array));

        },

        endOfListVisible(element_array) {
            var endoflist = document.getElementById(element_array.hiddenDivName).getBoundingClientRect();
            var listarrow = document.getElementById(element_array.listArrowName).getBoundingClientRect();

            if((endoflist.bottom - 60) > listarrow.bottom) {
                document.getElementById(element_array.listArrowName).style.visibility = 'visible';
                return true;

            } else {
                document.getElementById(element_array.listArrowName).style.visibility = 'hidden';
                return false;

            }

        },

        refreshLog: function(event) {
            this.turnOnLoadingMsg().then(() => {
                this.setDateFilter(event.target.value);
                document.getElementById('visitationlog-container').classList.remove('flash');
                this.getVisitationLog().then(() => {
                    this.checkListLength();
                    this.turnOffLoadingMsg();
                });

            });

        },

        showModal() {
            this.setModalType('new');
            this.setModalTypeOverride('no');
            // set time from and to based on current time
            var d = new Date();
            var h = d.getHours(); // (0-23)
            var ampmfrom;
            var ampmto;
            var hfrom;
            var hfromstr;
            var hto;
            var htostr;

            if((h > 0) && (h < 12)) {
                ampmfrom = "AM";
                hfromstr = ""+h;
                hto = h + 1;
                htostr = ""+hto;

                if(h < 11) {
                    ampmto = "AM";
                } else { // equals 11
                    ampmto = "PM";
                }

            } else if((h >= 12) && (h <= 23)) {
                ampmfrom = "PM";
                if(h === 12) {
                    hfrom = 12;
                    hfromstr = ""+hfrom;
                } else {
                    hfrom = h - 12;
                    hfromstr = ""+hfrom;
                }

                hto = h - 11;
                htostr = ""+hto;

                if(h < 23) {
                    ampmto = "PM";
                } else { // is equal to 23
                    ampmto = "AM";
                }

            } else { // midnight or morning 0
                ampmfrom = "AM";
                ampmto = "AM";
                h = 12;
                hfromstr = ""+h;

                hto = 1;
                htostr = ""+hto;

            }

            var m = d.getMinutes();
            var mstr;
            if(m >= 30) {
                mstr = ""+"30";
            } else {
                mstr = ""+"00";
            }
            var pad = "00";
            var newhfrom = pad.substring(0, pad.length - hfromstr.length) + hfromstr;
            var newhto = pad.substring(0, pad.length - htostr.length) + htostr;

            this.setHourFrom(newhfrom);
            this.setMinuteFrom(mstr);
            this.setHourTo(newhto);
            this.setMinuteTo(mstr);
            this.setAmPmFrom(ampmfrom);
            this.setAmPmTo(ampmto);
            this.getDateToday();

            this.$bvModal.show('visitationlog-modal');

        },

        editLogNote: function(event) {
            this.setModalType('edit');
            this.setRecordNo(event.target.getAttribute('record_no'));
            this.setRecordLNo(event.target.getAttribute('record_lno'));
            this.setDateSelected(event.target.getAttribute('date'));
            this.setSelectedShopNumber(event.target.getAttribute('employer_no'));
            this.shopfrag = event.target.getAttribute('employer_name');

            var time_from_str = event.target.getAttribute('time_from');
            var hourfrom = time_from_str.slice(0,2);
            var minutefrom = time_from_str.slice(3,5);
            var ampmfrom = time_from_str.slice(6,8);
            this.setHourFrom(hourfrom);
            this.setMinuteFrom(minutefrom);
            this.setAmPmFrom(ampmfrom);

            var time_to_str = event.target.getAttribute('time_to');
            var hourto = time_to_str.slice(0,2);
            var minuteto = time_to_str.slice(3,5);
            var ampmto = time_to_str.slice(6,8);
            this.setHourTo(hourto);
            this.setMinuteTo(minuteto);
            this.setAmPmTo(ampmto);

            this.setNoteText(event.target.getAttribute('ntext')).then(() => {
                this.$bvModal.show('visitationlog-modal');

            }).then(() => {
                document.getElementById('notecount').textContent = this.notelength;

            })

        },

        submitEntry() {
            if(this.selectedshopnumber === '') {
                return this.setErrorModal("Shop not selected.")
            }

            var text = document.getElementById('visitationlog-note').value;
            if(text === '') {
                return this.setErrorModal("No note is written.")

            }
            var fromtime = this.hourfrom+':'+this.minutefrom+' '+this.ampmfrom;
            var totime = this.hourto+':'+this.minuteto+' '+this.ampmto;

            var v = {
                employer_no: this.selectedshopnumber,
                selected_date: this.selecteddate,
                type: 'new',
                time_arrived: fromtime,
                time_departed: totime,
                visitation_description: text
            }

            this.setLogtoRecord(v).then(() => {
                this.turnOnLoadingMsg();
                this.recordVisitationLogNote().then(() => {
                    if(this.errormodal === '') {
                        this.getFilterOptions();
                        this.getVisitationLog().then(() => {
                            this.cancelModal();
                            this.turnOffLoadingMsg();

                        });
                    }

                });


            })
        },

        submitEdit() {
            this.turnOnLoadingMsg().then(() => {
                this.editRecord().then(() => {
                    if(this.errormodal === '') {
                        this.getVisitationLog().then(() => {
                            this.cancelModal();
                            this.turnOffLoadingMsg();

                        });
                    }

                });
            });

        },

        submitDelete() {
            this.turnOnLoadingMsg();
            this.deleteRecord().then(() => {
                if(this.errormodal === '') {
                    this.getFilterOptions();
                    document.querySelector("#selector > select").selectedIndex = 0;
                    var fakeevent = {
                        target: {
                            value: ''
                        }
                    }
                    this.refreshLog(fakeevent);
                    this.cancelModal();
                }

            });

        },

        filterBy() {
            if(this.shopfrag.length > 0) {
                this.setErrorModal('');
                document.getElementById('working-message').style.display = "block";
                this.setResultsShopNames('');
                this.searchShopNames(this.shopfrag).then(() => {
                    document.getElementById('working-message').style.display = "none";

                });

            }

        },

        fillName: function(event) {
            var empno = event.target.getAttribute('valueno');
            var empname = event.target.getAttribute('valuename');
            this.setResultsShopNames('');
            document.getElementById('companyname-search').value = empname;
            this.setSelectedShopNumber(empno);
            this.setErrorModal('');

        },

        getDateToday() {
            var d = new Date();
            var month = new Array();
                month[0] = "January";
                month[1] = "February";
                month[2] = "March";
                month[3] = "April";
                month[4] = "May";
                month[5] = "June";
                month[6] = "July";
                month[7] = "August";
                month[8] = "September";
                month[9] = "October";
                month[10] = "November";
                month[11] = "December";
            var m = d.getMonth() + 1;
            var dt = d.getDate();
            var y = d.getFullYear();
            var datestring = m+'-'+dt+'-'+y;
            this.todayis = month[d.getMonth()]+' '+dt+', '+y;
            this.setDateSelected(datestring)
            this.setDateToday(datestring)

        },

        pickDate() {
            document.getElementById('calendar').style.display = "block";

        },

        datePicked: function(event) {
            var dpd = event.getDate();
            var dpm = event.getMonth() + 1;

            // pad to two characters
            var dpdstr = "" + dpd;
            var dpmstr = "" + dpm;
            var pad = "00";
            var newdpd = pad.substring(0, pad.length - dpdstr.length) + dpdstr;
            var newdpm = pad.substring(0, pad.length - dpmstr.length) + dpmstr;

            var dpy = event.getFullYear();

            var datestring = newdpm+'-'+newdpd+'-'+dpy
            if(this.modaltype === 'edit') {
                this.setNewDateSelected(datestring)
            } else {
                this.setDateSelected(datestring);
            }

            document.getElementById('calendar').style.display = "none";

        },

        cancelModal() {
            this.setResultsShopNames('');
            this.setSelectedShopNumber('');
            this.setNoteText('');
            this.setNoteLength('0');
            this.setErrorModal('');
            this.setModalType('new');
            this.setModalTypeOverride('no');
            this.setHourFrom('');
            this.setMinuteFrom('');
            this.setAmPmFrom('');
            this.setHourTo('');
            this.setMinuteTo('');
            this.setAmPmTo('');
            this.setDateSelected('');
            this.setDeleteShow('no');
            this.setRecordNo('');
            this.setRecordLNo('');
            this.shopfrag = '';
            this.$bvModal.hide('visitationlog-modal');
        },

        deleteShowModal() {
            this.setDeleteShow('yes');
        },

        captureNoteText: function(event) {
            this.setNoteText(event).then(() => {
                document.getElementById('notecount').textContent = this.notelength;
            })
        }

    }


}

</script>

<style scoped>
#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 40vh;
    max-height: 40vh;
    z-index: 0;
}

#visitationlog-banner {
    vertical-align: middle;
    border-style: none;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 100%;
    float: right;
    z-index: 0;
}

#errormsg {
    background-color: white;
    margin-left: 5vw;
    min-width: 90vw;
    max-width: 90vw;
    margin-bottom: 2vh;
    color: red;
}

#loghistory-selector {
    position: absolute;
    color: white;
    top: 5em;
    min-height: 2.5em;
    z-index: 100;
    margin-left: 50vw;
    transform: translateX(-50%);
    font-weight: bold;
    font-size: 1.25em;
}

#visitationlog-box {
    position: fixed;
    color:white;
    top: 3.5em;
    min-height: 3.5em;
    width: 100vw;
    z-index: 100;
}

.visitationlog-secondary {
    text-align: center;
    width: 95%;
    font-size: 1.25em;
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.content {
    position: absolute;
    top: 10em;
    background-color: transparent;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    height: calc(100vh - 11.5em);
    overflow-y: scroll;
}

#visitationlog-container {
    position: absolute;
    margin-left: 5vw;
    z-index: 150;
    width: 90vw;
    min-height: calc(100vh - 18em);
    max-height: calc(100vh - 18em);
    overflow-y: scroll;
}

#visitationlog-container.flash {
    border-bottom: 1px solid #0d2365;
}

#visitationlog-list-arrow-more {
    position: fixed;
    top: calc(100vh - 4.5em);
    max-width: 5vw;
    transform: translateX(90vw);
    font-size: 2em;
    color: transparent;
}

#visitationlog-list-arrow-more.flash {
    color: #0d2365;
}

#visitationlog-results.fade {
    opacity: 1;
    max-width: 90vw;
    border-bottom: 1px solid Gray;
    transition: border 0.2s;
}

#visitationlog-list-arrow-more.fade {
    opacity: 1;
    color: DarkGray;
    transition: color 0.2s;
}

#visitationlog-hidden-div{
    height: 1px;
    visibility: hidden;
}

.visitationlog-section {
    max-width: 80vw;
    margin-left: 5vw;
}

.visitationlog-item {
    font-size: 0.9em;
    padding: 1em;
    border: 3px solid #e0e0e0;
    margin-bottom: 1em;
    border-radius: 0.25em;
    background-color: white;
}

.lognote-shop-row {
    font-size: 1.3em;
    font-weight: bold;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 2px solid #e0e0e0;
    text-decoration: underline;
    text-decoration-color: #0f70b7;
}

.lognote-row {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    overflow-x: hidden;
    font-weight: bold;
}

.lognote-note {
    white-space: pre-wrap;
    padding: 0.25em;
    font-family: monospace;
    font-size: 1.5em;
}

#note-container {
    overflow-y: scroll;
    min-height: 6em;
    max-height: 6em;
    border: 1px solid LightGray;
}

.visitationlog-header {
    position:absolute;
    top: calc(100vh - 15em);
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-height: 2.5em;
    max-height: 2.5em;
    width: 100%;
    z-index: 100;
}
.shop-name {
    overflow-x: scroll;
    white-space: nowrap;
}

#working-message {
    display: none;
    color: green;
    font-style: italic;
}

#error-modal {
    margin-top: 1em;
    color: red;
    font-weight: bold;
}

#selected-date {
    margin-top: 1em;
    font-weight: bold;
}

#calendar {
    display: none;
}

#visit-times {
    margin-top: 1em;
    font-weight: bold;
}

.time-from {
    padding-bottom: 1em;
}

.time-box {
    margin: 0 0 0 0;
    margin-right: 0.25em;
    padding: 0.25em;
    font-size: 1.5em;
    font-family: monospace;
}

.time-box select {
    color: white;
    background-color: black;
}

#visitationlog-note {
    margin-top: 1em;
    padding: 0.5em;
    border: 1px solid LightGray;
    font-size: 1.25em;
}

#button-container-top {
    display: flex;
    width: 87vw;
    justify-content: center;
}

.cancel-modal-link {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    text-align: center;
    text-decoration: none;
    outline: none;
    width: 35vw;
}

.cancel-modal-button {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-weight: bold;
    color: Red;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    border: 1px solid Red;
    width: 35vw;
}

.delete-modal-link {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    margin-left: 1em;
    text-align: center;
    text-decoration: none;
    outline: none;
    width: 35vw;
}

.delete-modal-button {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-weight: bold;
    background-color: Red;
    color: White;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    border: 1px solid Red;
    width: 35vw;
}

#button-container-bottom {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    display: flex;
    width: 92vw;
    justify-content: center;
}

.submit-entry-link {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    text-align: center;
    text-decoration: none;
    outline: none;
    width: 35vw;
}

.submit-entry-button {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    font-weight: bold;
    color: white;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color:  #0f70b7;
    width: 35vw;
}

.delete-entry-button {
    margin: auto;
    font-weight: bold;
    color: white;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    background-color:  Red;
    width: 35vw;

}

#add-entry {
    margin-bottom: 6.5em;
    background-color: #0f70b7;
    color: white;
    font-weight: bold;
    margin: auto;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-radius: .5em;
    border: 2px solid white;
    text-decoration: none;
    outline: none;
}

.footer {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    max-width: 100%;
    min-width: 100%;
    min-height: 1.5em;
    border-top: 1px solid white;
    z-index: 100;
    background-color: #0d2365;
}


</style>
